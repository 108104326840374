import store from '@/store';
import Cookies from 'js-cookie';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getFreight, setFreight, getShopConfig, setShopConfig, getSource, setSource, getAgentClient, setAgentClient } from '@/utils/cookies';
import { getLanguage } from '@/lang/index';

@Module({ dynamic: true, store, name: 'other' })
class Other extends VuexModule {
    public freight = getFreight() || {}; // 费用试算
    public language = getLanguage(); // 语言
    public shopConfig = getShopConfig() || {}; // 获取店铺配置
    public registeredSource = getSource() || ''; // 获取注册来源
    public agentClientId = getAgentClient() || ''; // 获取代理来源
    public homeBanner = JSON.parse(sessionStorage.getItem('homeBanner')) || [];

    @Mutation
    private SET_FREIGHT(data: Object) {
        this.freight = data;
    }

    @Mutation
    private SET_LANGUAGE(lang: string) {
        Cookies.set('language', lang);
        this.language = lang;
    }

    @Mutation
    private SET_SHOP_CONFIG(obj: Object) {
        setShopConfig(obj);
        this.shopConfig = obj;
    }

    // 设置来源
    @Mutation
    private SET_REGISTERD_SOURCE(str: any) {
        setSource(str);
        this.registeredSource = str;
    }

    // 设置代理来源
    @Mutation
    private SET_AGENT_CLIENT_ID(str: any) {
        setAgentClient(str);
        this.agentClientId = str;
    }

    // 设置首页banner缓存
    @Mutation
    private SET_BANNER(arr: any) {
        sessionStorage.setItem('homeBanner', JSON.stringify(arr));
        this.homeBanner = arr;
    }

    @Action
    public async setFreight(data: object) {
        setFreight(data);
        this.SET_FREIGHT(data);
    }

    @Action
    public async setLanguage(lang: string) {
        this.SET_LANGUAGE(lang);
    }

    @Action
    public async setShopConfig(obj: object) {
        this.SET_SHOP_CONFIG(obj);
    }

    @Action
    public async setRegisteredSource(str: any) {
        this.SET_REGISTERD_SOURCE(str);
    }

    @Action
    public async setAgentClientId(arr: any) {
        this.SET_AGENT_CLIENT_ID(arr);
    }

    @Action
    public async setHomeBanner(arr: any) {
        this.SET_BANNER(arr);
    }
}

export const otherModule = getModule(Other);
